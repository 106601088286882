import styled from "styled-components";
import { animated } from "react-spring";

export const HomeContainer = styled(animated.div)`
  position: absolute;
  max-width: 450px;
  z-index: 20;
  width: 60%;
  left: 10%;
  bottom: 10%;
  z-index: 10;
  user-select: none;

  @media screen and (max-height: 600px) {
    width: 50%;
  }

  @media screen and (max-width: 720px) and (orientation: portrait) {
    width: 60%;
  }

  @media screen and (max-width: 540px) and (orientation: portrait) {
    width: 70%;
  }

  @media screen and (max-height: 530px) and (orientation: landscape) {
    width: 40%;
  }
`;

export const Home = styled.div`
  img {
    width: 100%;
    height: auto;
  }
  background: transparent;
`;
