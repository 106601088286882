import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

const Wheel = styled.div`
  width: 18%;
  height: 18%;
  position: absolute;
  z-index: 2;
  bottom: 20%;
  left: 10.4%;
  user-select: none;
`;

const WheelFront = styled(Wheel)`
  left: 71.8%;
`;

export default ({img, movingCar}) => {
  const [reset, setReset] = useState(false);
  
  let propsWheel = useSpring({
    width: '100%',
    height: 'auto',
    userSelect: 'none',
    from: { transform: "rotate(0deg)" },
    to: [{ transform: movingCar ? "rotate(-359deg)" : "rotate(0deg)", config: { duration: 1000 } }],
    reset,
    delay: -1,
    onRest: () => {
      setReset(state => !state);
    }
  });

  return (
    <>
      <Wheel>
        <animated.img style={propsWheel} src={img} alt="wheel" />
      </Wheel>

      <WheelFront>
        <animated.img style={propsWheel} src={img} alt="wheel" />
      </WheelFront>
    </>
  );
};
