import styled from "styled-components";
import { animated } from "react-spring";

export const ControllerContainer = styled(animated.div)`
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 80px;
  line-height: 80px;
  bottom: 0;
  z-index: 30;
  background: rgba(255, 255, 255, 0.5);
`;

export const Controller = styled.div`
  display: flex;
  height: 80px;
  line-height: 80px;
`;

export const Panel = styled.div`
  width: 50%;
  display: flex;
  height: 80px;
  line-height: 80px;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.div`
  width: 100px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 10px;
  text-align: center;
  z-index: 40;
  line-height: 50px;
  cursor: pointer;
  user-select: none;
  color: rgb(0, 172, 189);
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  transition: 0.1s;
  &:hover {
    background: rgba(255, 255, 255, 0.7);
    font-size: 28px;
  }
`;

export const ImageButton = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 5% auto;
  margin: 0 20px;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    transform: scale(1.1);
    font-size: 28px;
  }
`;

export const Frame = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(animated.div)`
  min-width: 300px;
  max-width: 600px;
  width: 50%;
  background-color: rgba(245, 245, 245, 0.9);
  border-radius: 8px;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  padding: 32px;
`;

export const ContHeader = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: Arial;
  color: rgb(0, 172, 189); 
  text-align: center;
`;

export const ContGroupBox = styled.div`
  display: flex;
`;

export const ContGroup = styled.div`
  width: 40%;
  text-align: center;
  margin: 0 5%;
`;

export const ContLine = styled.div`
  display: flex;
  text-align: center;
`;

export const ContItem = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-family: Arial;
  color: rgb(0, 172, 189); 
  margin: 0 2px;
`;
