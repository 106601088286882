import styled from "styled-components";
import { animated } from "react-spring";

export const GridCenterContainer = styled(animated.div)`
  position: absolute;
  max-width: 550px;
  z-index: 20;
  width: 60%;
  top: 5%;
  margin-bottom: 10%;
  z-index: 5;

  @media screen and (max-height: 600px) {
    width: 50%;
  }

  @media screen and (max-width: 720px) and (orientation: portrait) {
    width: 60%;
  }

  @media screen and (max-width: 540px) and (orientation: portrait) {
    width: 70%;
  }

  @media screen and (max-height: 530px) and (orientation: landscape) {
    width: 40%;
  }
`;

export const GridCenter = styled.div`
  img {
    width: 100%;
    height: auto;
  }
  background: transparent;
`;
