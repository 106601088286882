import React, {useState} from "react";
import { useSpring, useTransition, config, animated } from "react-spring";
import { useImagesContext } from "../../../../contexts/ImagesContext";
import styled from "styled-components";
import {
  ControllerContainer, 
  Controller, 
  Panel, 
  Button, 
  ImageButton, 
  Frame, 
  Content, 
  Wrapper,
  ContHeader,
  ContGroupBox,
  ContGroup,
  ContLine,
  ContItem
 } from "./styles";
 import { initializeStore, useStore } from "../../../../common/Store";
 import shallow from "zustand/shallow";



const Dialog = ({ show, children }) => {
  const transitions = useTransition(show, null, {
    from: { transform: "translate3d(0,-30px,0)", opacity: 0 },
    enter: { opacity: 1, transform: "translate3d(0,0,0)" },
    leave: {
      opacity: 0,
      transform: "translate3d(0,10px,0)"
    }
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <Frame key={key} style={{ opacity: props.opacity }}>
          <Content style={props}>{children}</Content>
        </Frame>
      )
  );
};

export default props => {
  const { 
    wallet, 
    ev_type,
    ev_serial,
    ev_gunit,
    ev_cunit,
    ev_runit,
    ev_capa,
    ev_range,
    solar_type,
    solar_serial,
    solar_gunit,
    solar_cunit,
    solar_runit,
    solar_capa,
    solar_excs
} = useStore(
    (store) => ({
        wallet: store.wallet,
        ev_type: store.ev_type,
        ev_serial: store.ev_serial,
        ev_gunit: store.ev_gunit,
        ev_cunit: store.ev_cunit,
        ev_runit: store.ev_runit,
        ev_capa: store.ev_capa,
        ev_range: store.ev_range,
        solar_type: store.solar_type,
        solar_serial: store.solar_serial,
        solar_gunit: store.solar_gunit,
        solar_cunit: store.solar_cunit,
        solar_runit: store.solar_runit,
        solar_capa: store.solar_capa,
        solar_excs: store.solar_excs,
    }),
    shallow
);

  const { selectedGame, movingCar, chargeEnergy, setMovingCar, setChargeEnergy, consumeEnergy, chargeBattery, exceedCapacity, setConsumeEnergy, setChargeBattery, setExceedCapacity } = props;
  const { images } = useImagesContext();

  const propsController = useSpring({
    config: selectedGame ? { duration: 1000 } : config.slow,
    delay: 250
  }); 

  const [showSolarViewDialog, setShowSolarViewDialog] = useState(false);
  const [showBuyDialog, setShowBuyDialog] = useState(false);
  const [showSellDialog, setShowSellDialog] = useState(false);
  const [showChargeDialog, setShowChargeDialog] = useState(false);
  const [showEvViewDialog, setShowEvViewDialog] = useState(false);

  return (
    <ControllerContainer style={propsController}>
      <Controller>
         <Panel>
            <Button onClick={()=>setConsumeEnergy(true)}>Consume</Button>
            <Button onClick={()=>{if(!wallet) {alert("Please connect wallet"); return} setShowBuyDialog(true)}}>Buy</Button>
            <Button onClick={()=>{if(!wallet) {alert("Please connect wallet"); return} setShowSellDialog(true)}}>Sell</Button>
            <Button onClick={()=>{if(!wallet) {alert("Please connect wallet"); return} setShowSolarViewDialog(true)}}>View</Button>
         </Panel>
         <Panel>
            <Button onClick={()=>setMovingCar(true)}>Go</Button>
            <Button onClick={()=>{if(!wallet) {alert("Please connect wallet"); return} setShowChargeDialog(true)}}>Charge</Button>
            <Button onClick={()=>{if(!wallet) {alert("Please connect wallet"); return} setShowEvViewDialog(true)}}>View</Button>
         </Panel>
      </Controller>
      <Dialog show={showBuyDialog}>
        <Wrapper>
          <ContItem> You will pay {35}$ from your wallet. </ContItem>
          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
            <Button onClick={() => {setShowBuyDialog(false); setChargeBattery(21);}}>Buy</Button>
            <Button onClick={() => setShowBuyDialog(false)}>Close</Button>
          </div>
        </Wrapper>
      </Dialog>
      <Dialog show={showSellDialog}>
        <Wrapper>
          <ContItem> You will receive {10}$ to your wallet. </ContItem>
          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
            <Button onClick={() => {setShowSellDialog(false); setExceedCapacity(exceedCapacity - 10);}}>Sell</Button>
            <Button onClick={() => setShowSellDialog(false)}>Close</Button>
          </div>
        </Wrapper>
      </Dialog>
      <Dialog show={showSolarViewDialog}>
        <Wrapper>
          <ContHeader> Homeowner(Solar) Properties </ContHeader>
          <ContGroupBox>
            <ContGroup>
              <ContLine><ContItem>Solar Type : </ContItem><ContItem>{solar_type}</ContItem></ContLine>
              <ContLine><ContItem>Solar Serial : </ContItem><ContItem>{solar_serial}</ContItem></ContLine>
              <ContLine><ContItem>Generate Unit : </ContItem><ContItem>{solar_gunit}W/H</ContItem></ContLine>
              <ContLine><ContItem>Consume Unit : </ContItem><ContItem>{solar_cunit}W/H</ContItem></ContLine>
            </ContGroup>
            <ContGroup>
              <ContLine><ContItem>Charge Unit : </ContItem><ContItem>{solar_runit}W/H</ContItem></ContLine>
              <ContLine><ContItem>Capacity : </ContItem><ContItem>{solar_capa}W</ContItem></ContLine>
              <ContLine><ContItem>Excess Capacity : </ContItem><ContItem>{solar_excs}W</ContItem></ContLine>
            </ContGroup>
          </ContGroupBox>
          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
          <Button onClick={() => setShowSolarViewDialog(false)}>Close</Button>
          </div>
        </Wrapper>
      </Dialog>
      <Dialog show={showChargeDialog}>
        <Wrapper>
          <div style={{display: 'flex'}}>
          <ImageButton onClick={()=>{setShowChargeDialog(false); setChargeEnergy(51)}}>
            <img src={images["btn_chr.png"]} width="100" height="100" alt="btn_chr" />
          </ImageButton>
          <ImageButton onClick={()=>{setShowChargeDialog(false); setChargeEnergy(51)}}>
            <img src={images["btn_bat.png"]} width="100" height="100" alt="btn_bat" />
          </ImageButton>
          </div>
          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
          <Button onClick={() => setShowChargeDialog(false)}>Close</Button>
          </div>
        </Wrapper>
      </Dialog>
      <Dialog show={showEvViewDialog}>
        <Wrapper>
          <ContHeader> EV Properties </ContHeader>
          <ContGroupBox>
            <ContGroup>
              <ContLine><ContItem>EV Type : </ContItem><ContItem>{ev_type}</ContItem></ContLine>
              <ContLine><ContItem>EV Serial : </ContItem><ContItem>{ev_serial}</ContItem></ContLine>
              <ContLine><ContItem>Generate Unit : </ContItem><ContItem>{ev_gunit}W/H</ContItem></ContLine>
              <ContLine><ContItem>Consume Unit : </ContItem><ContItem>{ev_cunit}W/H</ContItem></ContLine>
            </ContGroup>
            <ContGroup>
              <ContLine><ContItem>Charge Unit : </ContItem><ContItem>{ev_runit}W/H</ContItem></ContLine>
              <ContLine><ContItem>Capacity : </ContItem><ContItem>{ev_capa}W</ContItem></ContLine>
              <ContLine><ContItem>Driving Range : </ContItem><ContItem>{ev_range}KM</ContItem></ContLine>
            </ContGroup>
          </ContGroupBox>
          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
          <Button onClick={() => setShowEvViewDialog(false)}>Close</Button>
          </div>
        </Wrapper>
      </Dialog>
    </ControllerContainer>
  );
};
