import React, {useState} from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import Web3 from "web3";
import { initializeStore, useStore } from "../../../common/Store";
import shallow from "zustand/shallow";
import {P2PNETWORK_ADDRESS, P2PUSER_ADDRESS, P2PEV_ADDRESS, P2PHOMEOWNER_ADDRESS} from "../../../common/Const";
import P2PNetwork from "../../../contracts/P2PNetwork.json";
import P2PUser from "../../../contracts/P2PUser.json";
import P2PEv from "../../../contracts/P2PEv.json";
import P2PHomeowner from "../../../contracts/P2PHomeowner.json";

const Connect = styled(animated.div)`
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  background: rgb(255, 255, 255, 0.5);
  position: absolute;
  right: 5%;
  top: 5%;
  z-index: 10;
  font-size: 20px;
  font-family: Arial;
  letter-spacing: 1px;
  user-select: none;
  @media screen and (max-width: 600px) {
    left: 25%;
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
    display: none;
  }
  &:hover {
    background: rgb(255, 255, 255, 0.9);
  }
`;

export default () => {
    const [loading, setLoading] = useState(false);
    const { 
        wallet, 
        setWallet, 
        setEvType,
        setEvSerial,
        setEvGUnit,
        setEvCUnit,
        setEvRUnit,
        setEvCapacity,
        setEvRange,
        setSolarType,
        setSolarSerial,
        setSolarGUnit,
        setSolarCUnit,
        setSolarRUnit,
        setSolarCapacity,
        setSolarExcess
    } = useStore(
        (store) => ({
            setWallet: store.setWallet,
            wallet: store.wallet,
            setEvType: store.setEvType,
            setEvSerial: store.setEvSerial,
            setEvGUnit: store.setEvGUnit,
            setEvCUnit: store.setEvCUnit,
            setEvRUnit: store.setEvRUnit,
            setEvCapacity: store.setEvCapacity,
            setEvRange: store.setEvRange,
            setSolarType: store.setSolarType,
            setSolarSerial: store.setSolarSerial,
            setSolarGUnit: store.setSolarGUnit,
            setSolarCUnit: store.setSolarCUnit,
            setSolarRUnit: store.setSolarRUnit,
            setSolarCapacity: store.setSolarCapacity,
            setSolarExcess: store.setSolarExcess,
        }),
        shallow
    );

  const handleConnect = () => {
    setLoading(true);
    if (String(wallet) !== '') {
        setWallet('');
        setLoading(false);
        return;
    }

    if (typeof window.web3 !== 'undefined') {
        window.web3 = new Web3(window.ethereum);     
    } else {
        alert("No Ethereum interface injected into browser. Read-only access");
    }

    window.ethereum.enable().then(function (accounts) {
        window.web3.eth.net.getNetworkType().then((network) => {
            if(network != "goerli"){
              alert("You are on " + network+ " network. Change network to goerli or you won't be able to do anything here");
              setLoading(false);
              return;
            } 
            let wallet = accounts[0]; 
            initializeContract(wallet);
        }).catch(function (err) {
            console.log(err);
        });  
    }).catch(function (error) {
        console.error(error)
    })    
  }

  const initializeContract = async (wallet) => {
    const P2PNetworkContract = new window.web3.eth.Contract(P2PNetwork.abi, P2PNETWORK_ADDRESS);
    const P2PUserContract = new window.web3.eth.Contract(P2PUser.abi, P2PUSER_ADDRESS);
    const P2PEvContract = new window.web3.eth.Contract(P2PEv.abi, P2PEV_ADDRESS);
    const P2PHomeownerContract = new window.web3.eth.Contract(P2PHomeowner.abi, P2PHOMEOWNER_ADDRESS);

    const ev_type = await P2PEvContract.methods.m_strType().call();
    setEvType(ev_type);
    const ev_serial = await P2PEvContract.methods.m_strSerial().call();
    setEvSerial(ev_serial);
    const ev_gunit = await P2PEvContract.methods.m_nGenerateUnit().call();
    setEvGUnit(ev_gunit);
    const ev_cunit = await P2PEvContract.methods.m_nConsumeUnit().call();
    setEvCUnit(ev_cunit);
    const ev_runit = await P2PEvContract.methods.m_nChargeUnit().call();
    setEvRUnit(ev_runit);
    const ev_capa = await P2PEvContract.methods.m_nChargeCapacity().call();
    setEvCapacity(ev_capa);
    const ev_range = await P2PEvContract.methods.m_nDrivingRange().call();
    setEvRange(ev_range);

    const solar_type = await P2PHomeownerContract.methods.m_strType().call();
    setSolarType(solar_type);
    const solar_serial = await P2PHomeownerContract.methods.m_strSerial().call();
    setSolarSerial(solar_serial);
    const solar_gunit = await P2PHomeownerContract.methods.m_nGenerateUnit().call();
    setSolarGUnit(solar_gunit);
    const solar_cunit = await P2PHomeownerContract.methods.m_nConsumeUnit().call();
    setSolarCUnit(solar_cunit);
    const solar_runit = await P2PHomeownerContract.methods.m_nChargeUnit().call();
    setSolarRUnit(solar_runit);
    const solar_capa = await P2PHomeownerContract.methods.m_nChargeCapacity().call();
    setSolarCapacity(solar_capa);
    const solar_excs = await P2PHomeownerContract.methods.m_nAvailableAmount().call();
    setSolarExcess(solar_excs);
    
    setWallet(wallet);
    setLoading(false);
  }

  const propsLogo = useSpring({
    config: { duration: 6000 },
    delay: 250
  });

  return <Connect style={propsLogo} onClick={()=>handleConnect()} >
            { loading ? <span style={{color: 'rgb(189, 142, 0)'}}>Loading...</span> : String(wallet) === '' ? <span style={{color: 'rgb(189, 142, 0)'}}>Connect</span> :
                      <span style={{color: 'rgb(189, 142, 0)'}}>{wallet.substr(0,4) + "..." + wallet.substr(wallet.length - 4)}</span>} 
         </Connect>
};
