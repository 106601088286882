import React, {useState} from "react";
import { useSpring, config, animated } from "react-spring";
import { useImagesContext } from "../../../../../contexts/ImagesContext";
import styled from "styled-components";

const Wrap = styled(animated.div)`
  position: absolute;
  max-width: 100px;
  z-index: 20;
  width: 30%;
  right: 14%;
  bottom: 5%;
  img {
    width: 100%;
    height: auto;
  }
  background: transparent;
`;

const MotorWheel = styled.div`
  width: 33%;
  height: 33%;
  position: absolute;
  z-index: 2;
  bottom: 15%;
  left: 18%;
  user-select: none;
`;

export default props => {
  const { selectedGame, consumeEnergy } = props;
  const { images } = useImagesContext();

  const Motor = ({img, consumeEnergy}) => {
    const [reset, setReset] = useState(false);
  
    const propsMotor = useSpring({
        width: '100%',
        height: 'auto',
        userSelect: 'none',
        from: { transform: "rotate(0deg)" },
        to: [{ transform: consumeEnergy ? "rotate(359deg)" : "rotate(0deg)", config: { duration: 1000 } }],
        reset,
        delay: -1,
        onRest: () => {
            setReset(state => !state);
        }
    });
  
    return <MotorWheel><animated.img style={propsMotor} src={img} alt="wheel"/></MotorWheel>
  }

  return (
    <Wrap>
        <img src={images["washer.png"]} alt="car" />
        <Motor img={images["washer_motor.png"]} consumeEnergy={consumeEnergy} />
    </Wrap>
  );
};
