import React, {useState} from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

const Gaz = styled(animated.div)`
  position: absolute;
  right: -6rem;
  bottom: 4rem;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  @media screen and (max-width: 490px) {
    width: 30px;
    height: 30px;
    bottom: 3rem;
    right: -3.5rem;
  }
`;

const GazMedium = styled(animated.div)`
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  right: -10rem;
  bottom: 5rem;
  width: 40px;
  height: 40px;

  @media screen and (max-width: 490px) {
    width: 20px;
    height: 20px;
    bottom: 3.5rem;
    right: -6rem;
  }
`;

const GazSmall = styled(animated.div)`
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  right: -12rem;
  bottom: 6rem;
  width: 20px;
  height: 20px;

  @media screen and (max-width: 490px) {
    width: 10px;
    height: 10px;
    bottom: 4rem;
    right: -7.5rem;
  }
`;

export default ({movingCar}) => {
  // TODO to useTrail
  const [reset, setReset] = useState(false);
  const spring = {
    from: movingCar ? { opacity: 1, transform: "scale(1)" } : { opacity: 0, transform: "scale(1)" },
    to: movingCar ? [
      { opacity: 0.5, transform: "scale(1.3)", config: { duration: 250 } },
      { opacity: 0, transform: "scale(0)", config: { duration: 250 } },
      { opacity: 0.5, transform: "scale(1.3)", config: { duration: 250 } },
      { opacity: 0, transform: "scale(0)", config: { duration: 250 } },
      { opacity: 0.5, transform: "scale(1.3)", config: { duration: 250 } },
      { opacity: 0, transform: "scale(0)", config: { duration: 250 } },
      { opacity: 0.5, transform: "scale(1.3)", config: { duration: 250 } },
      { opacity: 0, transform: "scale(0)", config: { duration: 250 } }
    ] : [],
    reset,
    onRest: () => {
      setReset(state => !state);
    }
  };

  let propsGaz = useSpring(spring);
  let propsGazBefore = useSpring({ ...spring, delay: 250 });
  let propsGazAfter = useSpring({ ...spring, delay: 550 });

 
  return (
    <>
      <Gaz style={propsGaz} />
      <GazMedium style={propsGazBefore} />
      <GazSmall style={propsGazAfter} />
    </>
  );
};
