import React from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

const Logo = styled(animated.div)`
  width: 30%;
  height: 30%;
  position: fixed;
  left: 5%;
  top: 5%;
  z-index: 10;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 5px;
  user-select: none;
  @media screen and (max-width: 600px) {
    left: 25%;
  }
  @media screen and (max-width: 600px) and (orientation: portrait) {
    display: none;
  }
`;

export default ({img}) => {
  const propsLogo = useSpring({
    config: { duration: 6000 },
    delay: 250
  });

  return <Logo style={propsLogo} >
            <span style={{color: 'rgb(189, 142, 0)'}}>Solar</span> 
            <span style={{color: 'rgb(0, 172, 189)'}}>Chain</span> 
         </Logo>;
};
