import React, { useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const Wrap = styled(animated.div)`
    position: absolute;
    top: 0;
    right: -15%;
    width: 150px;
    height: 40px;
    background: rgba(245, 245, 245, 0.8);
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 0 10px;
    font-family: Arial;
`;

const Properties = ({movingCar, chargeEnergy, setMovingCar, setChargeEnergy}) => {  
  const [number, api] = useSpring(()=>({
    from: { val: parseInt(chargeEnergy) },    
  }));

  useEffect(()=>{
      if (chargeEnergy === 100 && movingCar === true) {
        api({  /// Go
            to: [{ val: 50, config: { duration: 10000 }}],
            onRest: ()=>{
                setMovingCar(false);
            } 
        })
      }
      if (chargeEnergy === 51 && movingCar === false) {
        api({  /// Charge
            to: [{ val: 100, config: { duration: 2000 }}],
            onRest: ()=>{
                setChargeEnergy(100);
            } 
        })
      }
  }, [movingCar, chargeEnergy]);

  return (
    <Wrap>
        Battery:
        <animated.div>
            {number.val.interpolate(val => val.toFixed(1))}
        </animated.div>
        %
    </Wrap>
  );
};

export default Properties;
