import { useLayoutEffect } from "react";
import create, { UseStore } from "zustand";
import createContext from "zustand/context";
import { combine } from "zustand/middleware";

let store;

const initialState = {
  wallet: '',
  ev_type: '',
  ev_serial: '',
  ev_gunit: 0,
  ev_cunit: 0,
  ev_runit: 0,
  ev_capa: 0,
  ev_range: 0,
  solar_type: '',
  solar_serial: '',
  solar_gunit: 0,
  solar_cunit: 0,
  solar_runit: 0,
  solar_capa: 0,
  solar_excs: 0,
};

const zustandContext = createContext();
export const Provider = zustandContext.Provider;
export const useStore = zustandContext.useStore;

export const initializeStore = (preloadedState = {}) => {
  return create(
    combine({ ...initialState, ...preloadedState }, (set) => ({
      setWallet: (wallet) => {
        set({
          wallet: wallet,
        })
      },
      setEvType: (type) => {
        set({
          ev_type: type,
        })
      },
      setEvSerial: (serial) => {
        set({
          ev_serial: serial,
        })
      },
      setEvGUnit: (unit) => {
        set({
          ev_gunit: unit,
        })
      },
      setEvCUnit: (unit) => {
        set({
          ev_cunit: unit,
        })
      },
      setEvRUnit: (unit) => {
        set({
          ev_runit: unit,
        })
      },
      setEvCapacity: (capa) => {
        set({
          ev_capa: capa,
        })
      },
      setEvRange: (range) => {
        set({
          ev_range: range,
        })
      },
      setSolarType: (type) => {
        set({
          solar_type: type,
        })
      },
      setSolarSerial: (serial) => {
        set({
          solar_serial: serial,
        })
      },
      setSolarGUnit: (unit) => {
        set({
          solar_gunit: unit,
        })
      },
      setSolarCUnit: (unit) => {
        set({
          solar_cunit: unit,
        })
      },
      setSolarRUnit: (unit) => {
        set({
          solar_runit: unit,
        })
      },
      setSolarCapacity: (capa) => {
        set({
          solar_capa: capa,
        })
      },
      setSolarExcess: (excs) => {
        set({
          ev_excs: excs,
        })
      }
    }))
  );
};

export const useCreateStore = (initialState) => {
  // For SSR & SSG, always use a new store.
  if (typeof window === "undefined") {
    return () => initializeStore(initialState);
  }

  // For CSR, always re-use same store.
  store = store ?? initializeStore(initialState);
  // And if initialState changes, then merge states in the next render cycle.
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  
  
  return () => store;
};
