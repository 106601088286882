import React, { useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const Wrap = styled(animated.div)`
    position: absolute;
    top: -5%;
    left: -15%;
    width: 150px;
    height: 70px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;    
    padding: 5px 10px;
    font-family: Arial;
`;

const Line = styled(animated.div)`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const Properties = ({consumeEnergy, chargeBattery, setConsumeEnergy, setChargeBattery, exceedCapacity, setExceedCapacity}) => {  
  const [number, api] = useSpring(()=>({
    from: { val: parseInt(chargeBattery) },    
  }));

  const [exceed, api_ex] = useSpring(()=>({
    from: { val: parseInt(exceedCapacity) },    
  }));

  useEffect(()=>{
      if (chargeBattery === 100 && consumeEnergy === true) {
        api({  /// Go
            to: [{ val: 20, config: { duration: 10000 }}],
            onRest: ()=>{
              setConsumeEnergy(false);
            } 
        })
      }
      if (chargeBattery === 21 && consumeEnergy === false) {
        api({  /// Charge
            to: [{ val: 100, config: { duration: 1000 }}],
            onRest: ()=>{
              setChargeBattery(100);
            } 
        })
      }      
      api_ex({  /// Charge
          to: [{ val: exceedCapacity, config: { duration: 1000 }}],
          // onRest: ()=>{
          //   setExceedCapacity(100);
          // } 
      })
  }, [consumeEnergy, chargeBattery, exceedCapacity]);

  return (
    <Wrap>
        <Line>
          Battery:
          <animated.div>
              {number.val.interpolate(val => val.toFixed(1))}
          </animated.div>
          %
        </Line>
        <Line>
          Excess:
          <animated.div>
              {exceed.val.interpolate(val => val.toFixed(1))}
          </animated.div>
          W
        </Line>
    </Wrap>
  );
};

export default Properties;
