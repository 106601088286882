import React, {useState, useRef} from "react";
import { useSpring, useTransition, config, animated } from "react-spring";
import { useImagesContext } from "../../../../contexts/ImagesContext";
import styled from "styled-components";
import {
  ControllerContainer, 
  Controller, 
  Panel, 
  Button, 
  ImageButton, 
  Frame, 
  Content, 
  Wrapper,
  ContHeader,
  ContGroupBox,
  ContGroup,
  ContLine,
  ContItem
 } from "./styles";
import { initializeStore, useStore } from "../../../../common/Store";
import shallow from "zustand/shallow";
import {INTERVAL_ARRAY} from "../../Intro";

const Dialog = ({ show, children }) => {
  const transitions = useTransition(show, null, {
    from: { transform: "translate3d(0,-30px,0)", opacity: 0 },
    enter: { opacity: 1, transform: "translate3d(0,0,0)" },
    leave: {
      opacity: 0,
      transform: "translate3d(0,10px,0)"
    }
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <Frame key={key} style={{ opacity: props.opacity }}>
          <Content style={props}>{children}</Content>
        </Frame>
      )
  );
};

export default props => {
  const { 
    wallet, 
    ev_type,
    ev_serial,
    ev_gunit,
    ev_cunit,
    ev_runit,
    ev_capa,
    ev_range,
    solar_type,
    solar_serial,
    solar_gunit,
    solar_cunit,
    solar_runit,
    solar_capa,
    solar_excs,
} = useStore(
    (store) => ({
        wallet: store.wallet,
        ev_type: store.ev_type,
        ev_serial: store.ev_serial,
        ev_gunit: store.ev_gunit,
        ev_cunit: store.ev_cunit,
        ev_runit: store.ev_runit,
        ev_capa: store.ev_capa,
        ev_range: store.ev_range,
        solar_type: store.solar_type,
        solar_serial: store.solar_serial,
        solar_gunit: store.solar_gunit,
        solar_cunit: store.solar_cunit,
        solar_runit: store.solar_runit,
        solar_capa: store.solar_capa,
        solar_excs: store.solar_excs,
    }),
    shallow
);

  const { selectedGame, movingCar, chargeEnergy, setMovingCar, setChargeEnergy, consumeEnergy, chargeBattery, exceedCapacity, setConsumeEnergy, setChargeBattery, setExceedCapacity, activeInterval, setActiveInterval, activeBid, setActiveBid, valueMCP } = props;
  const { images } = useImagesContext();

  const propsController = useSpring({
    config: selectedGame ? { duration: 1000 } : config.slow,
    delay: 250
  }); 

  const [showIntervalDialog, setIntervalDialog] = useState(false);
  const [showBidDialog, setBidDialog] = useState(false);
  const [interval, setInterval] = useState(INTERVAL_ARRAY[0]);
  const homeownerRef = useRef();
  const evRef = useRef();

  const handleSetInterval = () => {
    setIntervalDialog(true);
  }

  const handleBid = () => {
    setBidDialog(true);
  }

  return (
    <ControllerContainer style={propsController}>
      <Controller>
        <Button style={{width: '250px'}} onClick={()=>handleSetInterval(true)}>Interval</Button>
        <Button style={{width: '250px'}} onClick={()=>handleBid(true)}>Bid</Button>
        {activeInterval && <div style={{color: 'rgb(0, 172, 189)', fontSize: '26px', fontFamily: 'Arial'}}>{valueMCP}MCP</div>}
      </Controller>
      <Dialog show={showIntervalDialog}>
        <Wrapper>
          {activeInterval ? <ContItem>
            <div>Selected Hours</div>
            <div>{interval}</div>
          </ContItem> : <ContItem>
            <div>Select Hours</div>
            <select style={{width: "80%"}} onChange={e=>setInterval(e.target.value)}>
              {
                INTERVAL_ARRAY.map(interval => {
                  return (
                    <option key={interval}>{interval}</option>      
                  );
                })
              }
            </select>
          </ContItem>}
          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
            <Button 
              onClick={() => {
                if (activeBid[0] < 1 || activeBid[1] < 1) {
                  alert("Please set Bit contents.")
                  return;
                }
                if (activeInterval) {
                  setIntervalDialog(false);
                  setActiveInterval("");
                } else {
                  setIntervalDialog(false); 
                  setActiveInterval(interval);
                }
              }
            }>{activeInterval ? "Close" : "Set"}</Button>
            <Button onClick={() => setIntervalDialog(false)}>Cancel</Button>
          </div>
        </Wrapper>
      </Dialog>    
      <Dialog show={showBidDialog}>
        <Wrapper>
          {activeBid[0] < 0 ? <ContItem>
            <div>Homeowner</div>
            <input ref={homeownerRef} type="number" style={{width: "60%"}}/>
          </ContItem> : <ContItem>
            <div>Homeowner</div>
            <div>{activeBid[0]}Kw</div>
          </ContItem>}
          {activeBid[1] < 0 ? <ContItem>
            <div>EvUser</div>
            <input ref={evRef} type="number" style={{width: "60%"}}/>
          </ContItem> : <ContItem>
            <div>EvUser</div>
            <div>{activeBid[1]}Kw</div>
          </ContItem>}
          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
            {(activeBid[0] < 0 && activeBid[1] < 0) && <Button 
              onClick={() => {
                if (!homeownerRef || !evRef) return;
                if (homeownerRef.current.value < 1 || evRef.current.value < 1) return;
                setActiveBid([homeownerRef.current.value, evRef.current.value])
                setBidDialog(false);
              }
            }>Bid</Button>}
            <Button onClick={() => setBidDialog(false)}>Cancel</Button>
          </div>
        </Wrapper>
      </Dialog>      
    </ControllerContainer>
  );
};
