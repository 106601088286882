import React from "react";
import { useSpring, config } from "react-spring";

import { useImagesContext } from "../../../../contexts/ImagesContext";
import Satellite from "./components/Satellite";
import Washer from "./components/Washer";
import Properties from "./components/Properties";
import Hearts from "./components/Hearts";

import {HomeContainer, Home } from "./styles";

export default props => {
  const { selectedGame, consumeEnergy, chargeBattery, setConsumeEnergy, setChargeBattery, exceedCapacity, setExceedCapacity } = props;
  const { images } = useImagesContext();

  let propsHome = useSpring({
    config: selectedGame ? { duration: 1000 } : config.slow,
    delay: 250
  });

  return (
    <HomeContainer style={propsHome}>
      <Home>
        <img src={images["homeowner.png"]} alt="home" />
        <Satellite />
      </Home>
      <Washer consumeEnergy={consumeEnergy} />
      <Hearts img={images["heart.svg"]} />
      <Properties 
        consumeEnergy={consumeEnergy} 
        chargeBattery={chargeBattery} 
        exceedCapacity={exceedCapacity}
        setConsumeEnergy={setConsumeEnergy} 
        setChargeBattery={setChargeBattery}
        setExceedCapacity={setExceedCapacity}
      />
    </HomeContainer>
  );
};
