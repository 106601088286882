import React, { useState, useEffect } from "react";

import Logo from "./components/Logo";
import Connect from "./components/Connect";
import Mark from "./components/Mark";
import GridCenter from "./components/GridCenter/GridCenter";
import Home from "./components/Home/Home";
import Car from "./components/Car/Car";
import Controller from "./components/Controller/Controller";
import BidActions from "./components/BidActions/BidActions";

import { useImagesContext } from "../../contexts/ImagesContext";

import {CarWithBubble} from  "./styles";


export const INTERVAL_ARRAY = [
  "30 mins",
  "1 hour",
  "2 hours",
  "5 hours",
  "12 hours",
  "24 hours"
];

export const INTERVAL_TIME = [
  1800,
  3600,
  7200,
  18000,
  43200,
  86400
];

let timerId = 0;
export default props => {
  const [selectedGame, setSelectedGame] = useState(false);
  const [consumeEnergy, setConsumeEnergy] = useState(false);
  const [chargeBattery, setChargeBattery] = useState(100);
  const [exceedCapacity, setExceedCapacity] = useState(200);
  const [movingCar, setMovingCar] = useState(false);
  const [chargeEnergy, setChargeEnergy] = useState(100);
  const [activeInterval, setActiveInterval] = useState("");
  const [activeBid, setActiveBid] = useState([-1, -1]);
  const [valueMCP, setValueMCP] = useState(0);
  const { images } = useImagesContext();
  const selectGameHandler = game => {
    setSelectedGame(true);
    setTimeout(() => props.history.push("/" + game), 1500);
  };

  const handleIntervalProc = () => {

  }

  const getInterval = (value) => {
    const index = INTERVAL_ARRAY.indexOf(value);
    return INTERVAL_TIME[index];
  }

  const getValueMCP = (value) => {    
    const time = value / 3600;
    const hB = parseInt(activeBid[0]);
    const eB = parseInt(activeBid[1]);
    const mcp = ((hB / eB) * ((hB + eB) / (time))) / hB;
    return mcp.toFixed(2);
  }

  useEffect(()=>{
    const interval = getInterval(activeInterval);
    if (activeInterval) {
      timerId = setTimeout(()=>handleIntervalProc(), interval);
      setValueMCP(getValueMCP(interval));
    } else {
      setActiveBid([-1, -1]);
      clearTimeout(timerId);
    }
  }, [activeInterval]);

  return (
    <>
      {/* <Mark img={images["mark.png"]} /> */}
      <Logo />
      <Connect />
      <BidActions 
        selectedGame={selectedGame} 
        consumeEnergy={consumeEnergy} 
        chargeBattery={chargeBattery} 
        exceedCapacity={exceedCapacity} 
        setConsumeEnergy={setConsumeEnergy} 
        setChargeBattery={setChargeBattery}
        movingCar={movingCar} 
        chargeEnergy={chargeEnergy} 
        setMovingCar={setMovingCar} 
        setChargeEnergy={setChargeEnergy}
        setExceedCapacity={setExceedCapacity}
        activeInterval={activeInterval}
        setActiveInterval={setActiveInterval}
        activeBid={activeBid}
        setActiveBid={setActiveBid}
        valueMCP={valueMCP}
      />
      <GridCenter selectedGame={selectedGame}/>
      <Home 
        selectedGame={selectedGame} 
        consumeEnergy={consumeEnergy} 
        chargeBattery={chargeBattery} 
        exceedCapacity={exceedCapacity} 
        setConsumeEnergy={setConsumeEnergy} 
        setChargeBattery={setChargeBattery} 
        setExceedCapacity={setExceedCapacity}
      />
      <CarWithBubble>
        <Car selectedGame={selectedGame} movingCar={movingCar} chargeEnergy={chargeEnergy} setMovingCar={setMovingCar} setChargeEnergy={setChargeEnergy}/>
      </CarWithBubble>
      <Controller 
        selectedGame={selectedGame} 
        consumeEnergy={consumeEnergy} 
        chargeBattery={chargeBattery} 
        exceedCapacity={exceedCapacity} 
        setConsumeEnergy={setConsumeEnergy} 
        setChargeBattery={setChargeBattery}
        movingCar={movingCar} 
        chargeEnergy={chargeEnergy} 
        setMovingCar={setMovingCar} 
        setChargeEnergy={setChargeEnergy}
        setExceedCapacity={setExceedCapacity}
      />
    </>
  );
};
