import React from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

const Mark = styled(animated.div)`
  width: 15%;
  height: 20%;
  position: fixed;
  right: 5%;
  top: 10%;
  z-index: 10;
  background-image: url(${({img}) => img});
  font-size: 40px;
  font-weight: bold;
  background-size: cover;
  user-select: none;
`;

export default ({img}) => {
  const propsMark = useSpring({
    config: { duration: 6000 },
    delay: 250
  });

  return <Mark style={propsMark} img={img}/>;
};
