import React from "react";
import { useSpring, config } from "react-spring";

import { useImagesContext } from "../../../../contexts/ImagesContext";
import Wheels from "./components/Wheel";
import Gaz from "./components/Gaz";
import Properties from "./components/Properties";

import {CarContainer, Car, CarShadow } from "./styles";

export default props => {
  const { selectedGame, movingCar, chargeEnergy, setMovingCar, setChargeEnergy } = props;
  const { images } = useImagesContext();

  let propsCar = useSpring({
    config: selectedGame ? { duration: 1000 } : config.slow,
    delay: 250
  });

  return (
    <CarContainer style={propsCar}>
      <Car>
        <img src={images["ev.png"]} alt="car" />
        <Wheels img={images["wheel.png"]} movingCar={movingCar} />
        <Gaz movingCar={movingCar}/>
        <CarShadow/>
      </Car>
      <Properties movingCar={movingCar} chargeEnergy={chargeEnergy} setMovingCar={setMovingCar} setChargeEnergy={setChargeEnergy}/>
    </CarContainer>
  );
};
