import React from "react";
import { useSpring, config } from "react-spring";

import { useImagesContext } from "../../../../contexts/ImagesContext";

import {GridCenterContainer, GridCenter } from "./styles";

export default props => {
  const { selectedGame } = props;
  const { images } = useImagesContext();

  let propsGridCenter = useSpring({
    config: selectedGame ? { duration: 1000 } : config.slow,
    delay: 250
  });

  return (
    <GridCenterContainer style={propsGridCenter}>
      <GridCenter>
        <img src={images["gridcenter.png"]} alt="gridcenter" />
      </GridCenter>
    </GridCenterContainer>
  );
};
